import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: false
    },
    themes: {
      light: {
        primary: "#53a318",
        secondary: "#f3f3f3",
        btn2: "#d3d2d2",
        accent: "#53A318",
        error: "#fd397a",
        info: "#53A318",
        success: "#0abb87",
        warning: "#ffb822"
      }
    }
  }
});

