import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      redirect: "/dashboard/overview",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "/dashboard/overview",
          name: "dashboard",
          component: () => import("@/view/pages/dashboard/Overview.vue")
        },
        {
          path: "/dashboard/clients",
          name: "clients",
          component: () => import("@/view/pages/dashboard/Clients.vue")
        },
        {
          path: "/dashboard/clients/items",
          name: "clientsitems",
          component: () => import("@/view/pages/dashboard/ClientsItems.vue")
        },
        {
          path: "/dashboard/items",
          name: "items",
          component: () => import("@/view/pages/dashboard/Items.vue")
        },
        {
          path: "/dashboard/invoice/:hash",
          name: "invoice-hash",
          component: () => import("@/view/pages/dashboard/invoice/Create.vue")
        },
        {
          path: "/dashboard/invoice",
          name: "invoice",
          component: () => import("@/view/pages/dashboard/invoice/Create.vue")
        }
      ]
    },
    {
      path: "/",
      redirect: "/administration",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "/administration/usermanagement",
          name: "usermanagement",
          component: () =>
            import("@/view/pages/usermanagement/Usermanagement.vue")
        },
        {
          path: "/administration/email",
          name: "email",
          component: () => import("@/view/pages/usermanagement/Email.vue")
        },
        {
          path: "/administration/settings",
          name: "settings",
          component: () => import("@/view/pages/usermanagement/Settings.vue")
        },
        {
          path: "/administration/synchronization",
          name: "synchronization",
          component: () =>
            import("@/view/pages/usermanagement/Synchronization.vue")
        }
      ]
    },
    {
      path: "/error",
      name: "error",
      component: () => import("@/view/pages/error/Error.vue")
    },
    {
      path: "/",
      component: () => import("@/view/pages/auth/login_pages/Login"),
      children: [
        {
          name: "login",
          path: "/login",
          component: () => import("@/view/pages/auth/login_pages/Login")
        }
      ]
    },
    {
      path: "*",
      redirect: "/404"
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/view/pages/error/Error.vue")
    }
  ]
});
