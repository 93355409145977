// Germany
export const locale = {
  OVERVIEW: {
    PAGETITLE: "Dokumente Übersicht",
    Description:
      "Bitte wählen Sie die Art des Dokumentes aus, welches Sie erstellen möchten.",
    DASHBOARD: "Hauptseite",
    Button_NewInvoice: "Rechnung",
    Button_CreditNote: "Gutschrift",
    Type: "Art",
    Number: "Nummer",
    Date: "Datum",
    Client: "Kunde",
    Status: "Status",
    Creater: "Ersteller",
    Options: "Optionen",
    LoadingMessage: "Loading documents... Please wait",
    AUTO_GENERATED: "Generiert",
    DRAFT: "ENTWURF",
    SEND: "GESENDET",
    SAVED: "GESPEICHERT",
  },
  MENU: {
    DASHBOARD: "Hauptseite",
    Administration: "Administration",
    Documents: "Dokumente",
    Clients: "Kunden",
    "Items/Article": "Artikel"
  },
  CREATE_DOCUMENT: {
    INVOICE: "RECHNUNG",
    "CREDIT NOTE": "GUTSCHRIFT"
  }
};
