import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/core/services/jwt.service";

/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
  init() {
    Vue.use(VueAxios, axios);

    //Vue.axios.defaults.baseURL = "http://localhost/etanotifier/public/api/"; //vm cutomer
    //Vue.axios.defaults.baseURL = "http://etanotifier.anventors.com/public/api/";  //anventors online url

    if (process.env.NODE_ENV === "development") {
      Vue.axios.defaults.baseURL = "http://127.0.0.1:8000/api/";
    } else {
      Vue.axios.defaults.baseURL =
        //"http://192.168.89.110/etanotifier/public/api/"; //vm cutomer
        //"http://invoice.anventors.com/api/public/api/"; //anventors online url
        //  "http://vm-srv173/demo/api/public/api/";
        "https://bhlapi.itexplus.com/api/";
    }
  },

  /**
   * Set the default HTTP request headers
   */
  setHeader() {
    Vue.axios.defaults.headers.common[
      "Authorization"
    ] = `bearer ${JwtService.getToken()}`;
  },

  query(resource, params) {
    return Vue.axios.get(params ? resource +'/'+ params : resource).catch(error => {
      // console.log(error);
      throw new Error(`ApiService ${error}`);
    });
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
  get(resource, slug = "") {
    return new Promise((resolve, reject) => {
      Vue.axios
        .get(`${resource}/${slug}`)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          //console.log(error.response);
          reject(error);
          throw new Error(`ApiService ${error}`);
        });
    });
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
  getFile(resource, slug = "") {
    return new Promise((resolve, reject) => {
      Vue.axios
        .get(`${resource}/${slug}`, { responseType: "arraybuffer" })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          //console.log(error.response);
          reject(error);
          throw new Error(`ApiService ${error}`);
        });
    });
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  post(resource, params) {
    return Vue.axios.post(`${resource}`, params);
  },

  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update(resource, slug, params) {
    return Vue.axios.put(`${resource}/${slug}`, params);
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put(resource, params) {
    return Vue.axios.put(`${resource}`, params);
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @returns {*}
   */
  delete(resource) {
    return Vue.axios.delete(resource).catch(error => {
      // console.log(error);
      throw new Error(`[RWV] ApiService ${error}`);
    });
  }
};

export default ApiService;
